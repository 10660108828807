import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import AboutGrid from "../components/about-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityResume(_id: { eq: "about" }) {
      title
      _rawDescription
      _rawResumeDescription
      clients {
        _key
        logo {
          asset {
            url
            _id
          }
        }
        client
        type {
          _key
          title
        }
        year
        company
      }
      resumeTable {
        _key
        sectionTitle
        sectionEntries {
          _key
          years
          entry
        }
      }
    }
  }
`;

const AboutPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const page = (data || {}).page;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const description = toPlainText(page._rawDescription) || site.description;

  return (
    <Layout slug="about">
      <SEO title="Levi Bergqvist" description={description} keywords={site.keywords} slug="about" />
      <Container>{page && <AboutGrid page={page} />}</Container>
    </Layout>
  );
};

export default AboutPage;
