import React from "react";
import { cn } from "../lib/helpers";
import Header from "./header";
import Contacts from "./contacts";

import "../styles/base.css";
import "../styles/layout.css";
import styles from "./layout.module.css";

const Layout = ({ slug = "", children, siteTitle }) => (
  <div className={cn(styles.root, slug ? styles[`${slug}Page`] : styles.defaultPage)}>
    <div className={cn(styles.fixedContainer)}>
      <Header siteTitle={siteTitle} />
    </div>

    <div className={cn(styles.content, styles.contentContainer)}>{children}</div>

    <footer className={styles.footer}>
      <div className={styles.footerBlock}>
        <Contacts footer />
      </div>
    </footer>
  </div>
);

export default Layout;
