import React from "react";
import BlockContent from "./block-content";
import styles from "./page-intro.module.css";
import { cn } from "../lib/helpers";

const PageIntro = ({ content, hideMore }) => {
  if (hideMore) {
    return <PageIntroWithMoreSection content={content} />;
  }

  return (
    <article className={cn(styles.root, styles.content)}>
      {content && <BlockContent blocks={content} />}
    </article>
  );
};

PageIntro.defaultProps = {
  hideMore: false
};

const PageIntroWithMoreSection = ({ content }) => {
  const firstParagraph = content.slice(0, 1);
  const otherContent = content.slice(1);

  return (
    <article className={cn(styles.root, styles.content)}>
      {firstParagraph && <BlockContent blocks={firstParagraph} />}

      {otherContent && (
        <details className={styles.details}>
          <summary className={styles.summary}>Read more &hellip;</summary>
          <BlockContent blocks={otherContent} />
        </details>
      )}
    </article>
  );
};

export default PageIntro;
