import React from "react";

import { cn } from "../lib/helpers";

import ClientModule from "./client-module";
import ResumeModule from "./resume-module";
import HeadingSection from "./preview-grid-heading";

import styles from "./about-grid.module.css";
import gridStyles from "./grid.module.css";

function AboutGrid({ page }) {
  return (
    <>
      <HeadingSection content={page._rawDescription} />

      <div className={cn(gridStyles.container, gridStyles.layoutFourCols)}>
        {page.clients.map(client => (
          <div className={gridStyles.block} key={client._key}>
            <ClientModule client={client} />
          </div>
        ))}
      </div>

      <HeadingSection content={page._rawResumeDescription} />

      <div className={gridStyles.container}>
        <div className={gridStyles.block}>
          <ResumeModule resume={page.resumeTable.slice(0, 2)} />
        </div>
        <div className={gridStyles.block}>
          <ResumeModule resume={page.resumeTable.slice(2)} />
        </div>
      </div>
    </>
  );
}

export default AboutGrid;
