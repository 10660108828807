import React from "react";
import { cn } from "../lib/helpers";
import PageIntro from "./page-intro";
import Contacts from "./contacts";

import styles from "./preview-grid-heading.module.css";

function HeadingSection({ content, sideContent, hideMore }) {
  return (
    <div className={styles.container}>
      <div className={styles.mainBlock}>
        <PageIntro content={content} hideMore={hideMore} />
      </div>

      {sideContent && <div className={styles.sideBlock}>{sideContent}</div>}
    </div>
  );
}

HeadingSection.defaultProps = {
  hideMore: false,
  showContacts: false
};

export default HeadingSection;
