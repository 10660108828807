import React from "react";
import styles from "./contacts.module.css";

const ContactsLink = ({ rel, ...props }) => (
  <a className={styles.link} {...props} rel={`nofollow ${rel}`} />
);

const Contacts = ({ footer = false }) => {
  const socialLinks = [
    { href: "https://www.instagram.com/studiolevibergqvist/", label: "Instagram" },
    { href: "https://www.facebook.com/studiolevibergqvist", label: "Facebook" },
    { href: "https://linkedin.com/company/studiolevibergqvist", label: "LinkedIn" }
  ];

  return (
    <ul className={styles.root}>
      <li>Studio Levi Bergqvist AS</li>
      <li>
        <ContactsLink href="https://goo.gl/maps/1qRAFceqtR5bgbSn9" target="_blank" rel="noreferrer">
          Schweigaardsgate 34 A
        </ContactsLink>
        <br /> 0191 Oslo, Norway
      </li>
      <li>
        <ContactsLink href="mailto:studio@levibergqvist.com">studio@levibergqvist.com</ContactsLink>
      </li>
      <li>
        <ContactsLink href="tel:+4745268247">+47 452 68 247</ContactsLink>
      </li>
      <li>
        {socialLinks.map((link, index) => (
          <React.Fragment key={`${index}-${link.href}`}>
            <ContactsLink href={link.href} target="_blank" rel="noreferrer">
              {link.label}
            </ContactsLink>
            {index < socialLinks.length - 1 ? ", " : ""}
          </React.Fragment>
        ))}
      </li>
      {footer && (
        <>
          <li className={styles.push}>
            Studio photos by{" "}
            <ContactsLink href="https://einaraslaksen.com" target="_blank" rel="noopener">
              Einar Aslaksen
            </ContactsLink>
          </li>
          {/* <li>
            Site developed by{" "}
            <ContactsLink href="/" rel="noopener">
              Paulius Friedt
            </ContactsLink>
          </li> */}
        </>
      )}
    </ul>
  );
};

export default Contacts;
