import { Link } from "gatsby";
import React, { useState, useEffect } from "react";

function LinkWithState(props) {
  const [fromPage, setFromPage] = useState("");

  const { state = {} } = props;

  useEffect(() => {
    if (location) {
      setFromPage(location.pathname.slice(1));
    }
  }, []);

  return <Link {...props} state={{ ...state, fromPage }} />;
}

export default LinkWithState;
