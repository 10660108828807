import React from "react";
import styles from "./resume-module.module.css";

const ResumeModule = ({ resume }) => (
  <>
    {resume.map(section => (
      <React.Fragment key={section._key}>
        <h4 className={styles.heading}>{section.sectionTitle}</h4>
        <ul className={styles.list}>
          {section.sectionEntries.map(entry => (
            <li key={entry._key}>
              <span>{entry.years}</span>
              {entry.entry}
            </li>
          ))}
        </ul>
      </React.Fragment>
    ))}
  </>
);

export default ResumeModule;
