import React from "react";
import Link from "./Link";
import styles from "./header.module.css";
import { cn } from "../lib/helpers";

const isPartiallyActive = ({ isCurrent, location }) => {
  if (isCurrent || location.pathname.startsWith("/projects/")) {
    return { className: cn(styles.link, styles.active) };
  }

  return null;
};

const Header = () => {
  return (
    <header>
      <nav className={styles.nav}>
        <span className={styles.linkContainer}>
          <Link
            className={styles.link}
            activeClassName={styles.active}
            getProps={isPartiallyActive}
            to={`/`}
          >
            Studio
          </Link>
        </span>
        <span className={styles.linkContainer}>
          <Link
            className={styles.link}
            partiallyActive
            activeClassName={styles.active}
            to={`/about`}
          >
            Levi Bergqvist
          </Link>
        </span>
        <span className={styles.linkContainer}>
          <Link
            className={styles.link}
            partiallyActive
            activeClassName={styles.active}
            to={`/posters`}
          >
            Posters
          </Link>
        </span>
      </nav>
    </header>
  );
};

export default Header;
