export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map(edge => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function sortNodes(nodes, sortOrder) {
  const nodesById = nodes.reduce((acc, p) => {
    acc[p.id] = p;
    return acc;
  }, {});

  return [...sortOrder].map(({ id }) => nodesById[id]);
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function buildSrcSet(imageUrl, sizes) {
  return sizes.reduce((srcSet, { width }) => {
    return (
      srcSet +
      `${imageUrl
        .width(width)
        .auto("format")
        .url()} ${width}w,`
    );
  }, "");
}

export function toPlainText(blocks = []) {
  return (
    blocks
      // loop through each block
      .map(block => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return "";
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map(child => child.text).join("");
      })
      .join("\n")
  );
}
