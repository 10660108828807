import React from "react";
import { prepCategories } from "../lib/meta";

import styles from "./client-module.module.css";

const ClientModule = ({ client }) => (
  <>
    <figure className={styles.logo}>
      {client.logo && client.logo.asset && <img src={client.logo.asset.url} />}
    </figure>
    <ul className={styles.list}>
      <li>
        <span>Client:</span>
        {client.client}
      </li>
      <li>
        <span>Type:</span>
        {prepCategories(client.type)}
      </li>
      <li>
        <span>Year:</span>
        {client.year}
      </li>
      <li>
        <span>Company:</span>
        {client.company}
      </li>
    </ul>
  </>
);

export default ClientModule;
