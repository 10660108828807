import { ucfirst } from "./string-utils";

export function prepCategories(categories) {
  const array = categories.reduce((acc, cat) => {
    acc.push(cat.title);
    return acc;
  }, []);

  return ucfirst(array.join(", ").toLowerCase());
}

export function prepMembers(members) {
  if (!members || members.length == 0) return [];

  const array = members.reduce((acc, member) => {
    acc.push({ label: member.role.title, value: member.name });
    return acc;
  }, []);

  return array;
}

export function prepProjectMeta(meta) {
  return [
    {
      label: "Client",
      value: meta.client
    },
    {
      label: "Year",
      value: meta.year
    },
    {
      label: "Type",
      value: prepCategories(meta.categories)
    },
    ...prepMembers(meta.members),
    {
      label: "Company",
      value: meta.design || "Studio Levi Bergqvist"
    }
  ];
}

export function prepPosterMeta(meta) {
  return [
    {
      label: "Year",
      value: meta.year
    },
    {
      label: "Client",
      value: meta.client
    },
    ...prepMembers(meta.members),
    {
      label: "Company",
      value: meta.design || "Studio Levi Bergqvist"
    }
  ];
}
