import { useEffect } from "react";
import LazyLoad from "vanilla-lazyload";

const lazyLoadConfig = {
  elements_selector: ".lazy"
};

export function useLazyLoad() {
  useEffect(() => {
    if (window && !window.lazyLoadInstance) {
      window.lazyLoadInstance = new LazyLoad(lazyLoadConfig);
    }
  }, []);
}

export function useLazyImage() {
  useEffect(() => {
    if (window && window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
      return () => {
        window.lazyLoadInstance.update();
      };
    }
  }, []);
}
